import Link from 'next/link';
import { NavigationItemParams } from '@/presentation/components/BottomNavigation/component';
import { NavigationIcon } from '@/presentation/components/BottomNavigation/components/NavigationIcon';
import { Anchor } from '@/presentation/components/BottomNavigation/style';
import { useTranslation } from '@/presentation/hooks/useTranslation';
import { getAddOfferUrl } from '@/presentation/services/urls';
import { CirclePlus } from '@pelando/fontawesome/icons';
import { useRouter } from 'next/router';

export function AddOfferNavigationItem({
  onClick,
  active,
  count,
}: NavigationItemParams) {
  const { t } = useTranslation('header');
  const { locale } = useRouter();

  return (
    <Link
      href={getAddOfferUrl(locale)}
      passHref
      prefetch={false}
      legacyBehavior
    >
      <Anchor onClick={onClick} aria-current={active}>
        <NavigationIcon icon={CirclePlus} count={count} />
        <span>{t('post-link-text')}</span>
      </Anchor>
    </Link>
  );
}
